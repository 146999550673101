import React, { FC } from 'react';

import ProductCarousel from 'gatsby-theme-dettol/src/components/common/ProductCarousel';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import { CarouselMasks } from 'gatsby-theme-dettol/src/components/common/ProductCarousel/models';
import { IProductInfoGallery } from 'gatsby-theme-dettol/src/components/ProductInfo/models';
import ImageFixed from 'gatsby-theme-dettol/src/components/common/Image/ImageFixed';

import 'gatsby-theme-dettol/src/components/ProductInfoGallery/ProductInfoGallery.scss';

const getCurrentImage = (image, alt, isFixedType) =>
  image ? (
    <button
      type="button"
      className="dt-product-info-gallery__item"
      key={image.childImageSharp.fluid.src}
    >
      {isFixedType && image?.fixedMobileGalleryImage ? (
        <ImageFixed
          mobileImage={image?.fixedMobileGalleryImage}
          desktopImage={image?.fixedDesktopGalleryImage}
          alt={alt}
          lazyLoad={false}
        />
      ) : image.childImageSharp?.fluid ? (
        <UmbracoImage
          alt={alt}
          image={{ ...image.childImageSharp, fallbackUrl: image.childImageSharp?.fluid?.src }}
          lazyLoad={false}
          transform={false}
        />
      ) : null}
    </button>
  ) : null;

const ProductInfoGallery: FC<IProductInfoGallery> = ({
  imgSet,
  alt,
  backGroundImage,
  useFixedProductImage,
}) => {
  return (
    <div className="dt-product-info-gallery">
      <UmbracoImage
        alt={alt}
        image={backGroundImage}
        className="dt-umbraco-image__bg"
        transform={false}
      />
      {imgSet.length > 1 ? (
        <ProductCarousel type={CarouselMasks.singleElement} isBtnGroup>
          {imgSet.map((image) => getCurrentImage(image, alt, useFixedProductImage))}
        </ProductCarousel>
      ) : (
        <div className="dt-carousel">{getCurrentImage(imgSet[0], alt, useFixedProductImage)}</div>
      )}
    </div>
  );
};
export default ProductInfoGallery;
